<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="mb-4">
        <div class="p-2">
          <b-button variant="primary" @click="abrirCadastrar">{{ $t("GERAL.CADASTRAR") }}</b-button>
        </div>

        <b-table head-variant="light" hover :items="items" :fields="campos">
          <template v-slot:cell(acoes)="item">
            <div class="flex">
              <b-dropdown variant="outline-default">
                <b-dropdown-item @click="abrirEditar(item.item.id)">{{ $t("GERAL.EDITAR") }}</b-dropdown-item>
                <b-dropdown-item @click="excluir(item.item)">{{ $t("GERAL.EXCLUIR") }}</b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import PermissoesService from "@/common/services/permissoes/permissoes.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import Confirmacao from "@/components/confirmacao/Confirmacao";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import helpers from "@/common/utils/helpers";


export default {
  name: "Index",
  data() {
    return {
      permissions: helpers.validarAcesso("Usuario"),
      campos: [
        {
          key: "nome",
          label: this.$t("GERAL.PERFIL"),
        },
        {
          key: "acoes",
          label: this.$t("GERAL.ACOES"),
          tdClass: "acoes",
        },
      ],
      items: [],
      totalRows: 5,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: this.$t("GERAL.PAGINACAO") }],
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [{ titulo: this.$t("MENU.PERFIL") }]);
    this.listar();
  },
  methods: {
    listar() {
      this.$store.dispatch(START_LOADING);
      PermissoesService.listar()
        .then((result) => {
          result.data.data.itens.sort((a, b) => a.nome.localeCompare(b.nome));
          this.items = result.data.data.itens;
          this.totalRows = result.data.data.paginacao.totalDeElementos / this.perPage;
        })
        . catch(() => {
          
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    abrirCadastrar() {
      this.$router.push({ name: "permissoes-formulario" });
    },
    abrirEditar(id) {
      this.$router.push({ name: "permissoes-formulario", params: { id } });
    },
    excluir(item) {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("PERMISSOES.CONFIRMAR_EXCLUIR").formatUnicorn({
          nome: item.nome,
        })
      ).then(async (result) => {
        if (result.value) {
          this.$store.dispatch(START_LOADING);
          PermissoesService.excluir(item.id)
            .then(() => {
              this.$router.go();
            })
            .catch(() => {
              
            })
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
  },
};
</script>
